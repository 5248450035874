var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      class: [
        { closeable: _vm.closeable, closed: _vm.closed, reverse: _vm.reverse },
        "floating-tabs",
      ],
      attrs: {
        "fill-height": "",
        fillWidth: _vm.vertical || !_vm.closeable,
        vertical: _vm.vertical,
        reverse: _vm.reverse,
        justify: "flex-start",
        align: "flex-start",
      },
    },
    [
      _c(
        "cs-flex",
        {
          staticClass: "tab-header",
          attrs: {
            fillHeight: !_vm.vertical,
            fillWidth: _vm.vertical,
            align: "center",
            justify: _vm.tabPlacement,
            vertical: !_vm.vertical,
          },
        },
        [
          _c(
            "cs-flex",
            { staticClass: "tab-header-before" },
            [_vm._t("tab-header-before")],
            2
          ),
          _c(
            "cs-flex",
            { attrs: { vertical: !_vm.vertical } },
            _vm._l(_vm.tabItems, function (item, index) {
              return _c("floating-tab-item", {
                key: index,
                attrs: {
                  icon: item.icon,
                  text: item.text,
                  iconProps: item.iconProps,
                  disabled: item.disabled,
                  active: index === _vm.activeIndex_ && !_vm.closed,
                  closeable: _vm.closeable,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleClick(index)
                  },
                },
              })
            }),
            1
          ),
          _c(
            "cs-flex",
            { staticClass: "folder-breadcrumbs" },
            [_vm._t("breadcrumbs")],
            2
          ),
          _c(
            "cs-flex",
            { staticClass: "tab-header-after" },
            [_vm._t("tab-header-after")],
            2
          ),
        ],
        1
      ),
      _c("v-divider", {
        staticClass: "tab-separator",
        attrs: { vertical: !_vm.vertical },
      }),
      _c(
        "cs-flex",
        {
          class: [{ closed: _vm.closed }, "tab-content"],
          style: `--content-width: ${_vm.contentWidth}px;`,
          attrs: { "fill-height": "", "fill-width": "", reverse: _vm.reverse },
        },
        [
          _c(
            "keep-alive",
            [
              _c(
                _vm.currentTabContent,
                _vm._g(
                  _vm._b(
                    { tag: "component" },
                    "component",
                    _vm.currentTabProps,
                    false
                  ),
                  { ..._vm.$listeners, ..._vm.currentTabListeners }
                )
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: !_vm.vertical } }),
          _c(
            "cs-flex",
            { staticClass: "tab-content-after", attrs: { "fill-height": "" } },
            [_vm._t("tab-content-after")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }