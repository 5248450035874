var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isSectionFamilySelected
    ? _c(
        "div",
        [
          _c("div", { staticClass: "mb-1" }, [
            _vm._v(" " + _vm._s(_vm.$t(_vm.tableTitle)) + " "),
          ]),
          _c(
            "cs-flex",
            {
              staticClass: "manager-rules-dialog-table-data",
              attrs: { vertical: "" },
            },
            [
              _vm._l(_vm.sections, function (section, index) {
                return [
                  _c(
                    "cs-flex",
                    {
                      key: index,
                      class: [
                        section.checkedByUser && !_vm.isTableReadOnly
                          ? "manager-rules-dialog-table-data-row"
                          : "",
                      ],
                      attrs: { justify: "start" },
                    },
                    [
                      _c("v-checkbox", {
                        staticClass: "mt-0 pt-0",
                        attrs: {
                          readonly: _vm.isTableReadOnly,
                          "input-value": _vm.isTableReadOnly
                            ? section.readOnlyChecked
                            : section.checkedByUser,
                          disabled: _vm.isTableReadOnly || _vm.tableDisabled,
                          "hide-details": "",
                          dense: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.checkboxChanged(
                              section.data.id,
                              ...arguments
                            )
                          },
                        },
                      }),
                      _vm._v(
                        _vm._s(_vm.getSectionOrMaterialName(section.data.name))
                      ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      )
    : _c("div", [
        _c("div", {
          staticClass: "manager-rules-dialog-table-title-placeholder mb-1",
        }),
        _c("div", {
          staticClass: "manager-rules-dialog-table-data-placeholder",
        }),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }