
import { CsFlex } from '@consteel/csuetify'
import Vue, { VueConstructor } from 'vue'
import FloatingTabItem, { IFloatingTabItemProps } from './FloatingTabItem.vue'

const TAB_PLACEMENTS = ['flex-start', 'center', 'flex-end'] as const
type TabPlacement = (typeof TAB_PLACEMENTS)[number]
export default Vue.extend({
  name: 'FloatingTabs',
  components: {
    CsFlex,
    FloatingTabItem,
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
    tabItems: {
      required: true,
      type: Array as () => (IFloatingTabItemProps & {
        content: VueConstructor
        props?: Record<string, unknown>
        listeners?: Record<string, unknown>
      })[],
    },
    vertical: {
      required: false,
      type: Boolean,
      default: false,
    },
    tabPlacement: {
      required: false,
      type: String as () => TabPlacement,
      default: 'flex-start',
      validator: (prop: TabPlacement) => {
        return TAB_PLACEMENTS.includes(prop)
      },
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    contentWidth: {
      required: false,
      type: [Number, String],
      default: 350,
    },
    defaultClosed: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data(): { activeIndex_: number; closed: boolean } {
    return {
      activeIndex_: this.activeIndex,
      closed: false,
    }
  },
  watch: {
    activeIndex(val: number) {
      this.activeIndex_ = val
    },
  },
  computed: {
    currentTabContent(): unknown {
      return this.tabItems[this.activeIndex_].content
    },
    currentTabProps(): unknown {
      return this.tabItems[this.activeIndex_].props
    },
    currentTabListeners(): unknown {
      return this.tabItems[this.activeIndex_].listeners
    },
  },
  methods: {
    handleClick(index: number) {
      if (this.activeIndex_ !== index) {
        this.activate(index)
        this.$emit('tabIconClick', index)
      } else if (this.closeable) {
        this.closed = !this.closed
      }
    },
    activate(index: number) {
      this.activeIndex_ = index
      this.closed = false
    },
  },
  created() {
    this.closed = this.defaultClosed
  },
})
