var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-grid",
    {
      staticClass: "manager-rules-parameter",
      attrs: {
        cols: 3,
        rows: 1,
        justify: "start",
        templateColumns: "0.5fr 5fr 4.5fr",
      },
    },
    [
      _c("v-checkbox", {
        staticClass: "mt-0 pt-0",
        attrs: {
          "hide-details": "",
          dense: "",
          "input-value": _vm.parameterSelectorDisabled ? false : true,
          disabled: _vm.disableParameter,
        },
        on: { change: _vm.checkboxChanged },
      }),
      _vm._v(" " + _vm._s(_vm.convertRawSectionParameter + ":") + " "),
      _c(
        "cs-flex",
        {
          attrs: { horizontal: "", justify: "space-between", "fill-width": "" },
        },
        [
          _c("v-select", {
            staticClass: "manager-rules-parameter-selector",
            attrs: {
              items: _vm.selectorDirectionItems,
              disabled: _vm.parameterSelectorDisabled || _vm.disableParameter,
              "item-text": "text",
              "item-value": "value",
              dense: "",
              "hide-details": "",
              flat: "",
            },
            on: { change: _vm.selectorChanged },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function ({ item }) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-flex justify-center manager-rules-parameter-selector-slot",
                      },
                      [_vm._v(" " + _vm._s(item.text) + " ")]
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selectedDirectionValue,
              callback: function ($$v) {
                _vm.selectedDirectionValue = $$v
              },
              expression: "selectedDirectionValue",
            },
          }),
          _c("v-select", {
            staticClass: "manager-rules-parameter-selector",
            attrs: {
              items: _vm.selectorStepItems,
              disabled: _vm.parameterSelectorDisabled || _vm.disableParameter,
              "item-text": "text",
              "item-value": "value",
              dense: "",
              "hide-details": "",
              flat: "",
            },
            on: { change: _vm.selectorChanged },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function ({ item }) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-flex justify-center manager-rules-parameter-selector-slot",
                      },
                      [_vm._v(" " + _vm._s(item.text) + " ")]
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selectedStepValue,
              callback: function ($$v) {
                _vm.selectedStepValue = $$v
              },
              expression: "selectedStepValue",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }