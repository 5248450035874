import { render, staticRenderFns } from "./ManagerRuleEditorDialog.vue?vue&type=template&id=60ae9796&scoped=true"
import script from "./ManagerRuleEditorDialog.vue?vue&type=script&lang=ts"
export * from "./ManagerRuleEditorDialog.vue?vue&type=script&lang=ts"
import style0 from "./ManagerRuleEditorDialog.vue?vue&type=style&index=0&id=60ae9796&lang=sass&scoped=true"
import style1 from "./ManagerRuleEditorDialog.vue?vue&type=style&index=1&id=60ae9796&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60ae9796",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60ae9796')) {
      api.createRecord('60ae9796', component.options)
    } else {
      api.reload('60ae9796', component.options)
    }
    module.hot.accept("./ManagerRuleEditorDialog.vue?vue&type=template&id=60ae9796&scoped=true", function () {
      api.rerender('60ae9796', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Dialogs/ManagerRulesDialog/ManagerRuleEditorDialog.vue"
export default component.exports