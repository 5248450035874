import { doRequest, downloadFile } from '@/services/requests'
import { SteelspaceResponse } from '@/types'
import {
  CreateDefaultLengthDto,
  CreateDefaultWasteDto,
  SupplyDefaultLength,
  SupplyOptimization,
  SupplyPortionInfo,
  SupplySummaryLength,
} from '../types'
import { SupplyDefaultWaste } from '../types/SupplyDefaultWaste.model'

export const createDefaultLengthRequest = async (
  projectId: string,
  modelId: string,
  length: number
): Promise<SupplyDefaultLength | undefined> => {
  const dto = new CreateDefaultLengthDto(length)

  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/supplies/defaultLength`,
    'POST',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 201) {
    const result: SupplyDefaultLength = <SupplyDefaultLength>responseJson.payload
    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const createDefaultWasteRequest = async (
  projectId: string,
  modelId: string,
  wastePercentage: number
): Promise<SupplyDefaultWaste | undefined> => {
  const dto = new CreateDefaultWasteDto(wastePercentage)

  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/supplies/defaultWaste`,
    'POST',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 201) {
    const result: SupplyDefaultWaste = <SupplyDefaultWaste>responseJson.payload
    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const createSupplyOptimizationRequest = async (
  projectId: string,
  modelId: string,
  modelFile: File,
  portions: SupplyPortionInfo[],
  supplySummaryLegths: SupplySummaryLength[],
  tolerance: number
): Promise<SupplyOptimization | undefined> => {
  const formData = new FormData()
  formData.append('Model', modelFile)
  formData.append('Portions', JSON.stringify(portions))
  formData.append('SupplySummaryLengths', JSON.stringify(supplySummaryLegths))
  formData.append('Tolerance', tolerance.toString())

  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/optimization/supply`,
    'POST',
    formData,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    false
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 201) {
    const result: SupplyOptimization = <SupplyOptimization>responseJson.payload

    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const updateDefaultWasteRequest = async (
  projectId: string,
  modelId: string,
  wastePercentage: number
): Promise<SupplyDefaultWaste | undefined> => {
  const dto = new CreateDefaultWasteDto(wastePercentage)

  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/supplies/defaultWaste`,
    'PUT',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    const result: SupplyDefaultWaste = <SupplyDefaultWaste>responseJson.payload

    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const removeDefaultLengthRequest = async (
  projectId: string,
  modelId: string,
  defaultLengthId: string
): Promise<boolean | undefined> => {
  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/supplies/defaultLength/${defaultLengthId}`,
    'DELETE',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return true
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const exportSupplyOptimizationRequest = async (
  projectId: string,
  modelId: string,
  supplyOptId: string
): Promise<void> => {
  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/optimization/supply/${supplyOptId}/export`,
    'GET',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    false
  )

  if (response.status === 200) {
    downloadFile(await response.blob(), `beszerzés_eredmény_export.xlsx`)
  }
}
