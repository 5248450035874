
import Vue from 'vue'
import {
  CsFlex,
  CsGrid,
  CsTextField,
  CsBtn,
  CsSelectTree,
  CsSimpleDialog,
} from '@consteel/csuetify'
import InfoSvg from '@/assets/common/Info.svg.vue'
import {
  getSections,
  getSectionFamilies,
  getSectionParameters,
  getSectionTypes,
  onStockStore,
  getSectionOrigins,
  getMaterials,
  createManagerRule,
  createPreviewManagerRuleSection,
  createPreviewManagerRuleMaterial,
  getLatestSectionBank,
  modifyManagerRule,
  setOnStockModelManagerRules,
  ParameterDirections,
  OpenOptions,
  SelectorOptions,
  ParameterNames,
  ParameterSteps,
} from '@/modules/onstock'
import {
  SectionBankData,
  ManagerRuleTreeNode,
  SectionManagerRuleParameter,
  MaterialManagerRuleParameter,
  ManagerRuleTableData,
  ParameterData,
  DefaultManagerRuleData,
} from '@/modules/onstock/types/OnStockManagerRuleInterfaces'
import ManagerRulesParameter from './ManagerRulesParameter.vue'
import ManagerRulesPreviewTable from './ManagerRulesPreviewTable.vue'
import { OnStockPreviewManagerRuleBase } from '@/modules/onstock/types/OnStockManagerRules.model'

export default Vue.extend({
  name: 'ManagerRuleEditorDialog',
  components: {
    CsFlex,
    CsGrid,
    CsTextField,
    InfoSvg,
    CsBtn,
    CsSelectTree,
    CsSimpleDialog,
    ManagerRulesParameter,
    ManagerRulesPreviewTable,
  },
  props: {
    value: { type: Boolean, default: false },
    maxWidth: { type: Number, default: 560 },
    dropDownMenuHight: { type: Number, default: 300 },
    defaultSelectorDirection: { type: Number, default: ParameterDirections.UpAndDown },
    defaultSelectorStep: { type: Number, default: ParameterSteps.Default },
    defaultManagerRuleDialogData: {
      type: Object as () => DefaultManagerRuleData,
      default: () => ({
        openOption: OpenOptions.Write,
        managerRuleId: '',
        dialogName: '',
        selectedSectionFamily: { text: '', id: '' },
        parameterSettings: [],
        sectionTableData: [],
      }),
    },
  },
  data() {
    return {
      SelectorOptions,
      ParameterNames,
      latestSectionBank: {} as SectionBankData,
      items: [] as ManagerRuleTreeNode[],
      selectedIds: [] as string[],
      level: 0,
      found: false,
      selectedPath: [] as { id: string; normalizedName: string }[],
      saveSelectedSectionType: { text: '', id: '' },
      selectedSectionTypeValue: 0,
      selectorBtn: SelectorOptions.Section,
      nameTextField: '',
      parameterSettings: [] as ParameterData[],
      selectedSectionId: '',
      selectedMaterialId: '',
      savedMaterialTableData: [] as ManagerRuleTableData[],
      savedSectionTableData: [] as ManagerRuleTableData[],
      changedParameterType: SelectorOptions.None,
      dialogLoading: false,
      tableLoading: false,
    }
  },
  async mounted() {
    const sectionBank = await getLatestSectionBank()

    if (!sectionBank) {
      console.warn('Section bank not found')
      return
    }

    this.latestSectionBank = {
      ...sectionBank,
      name: sectionBank.name,
    }

    this.items = await this.getSectionData(
      this.latestSectionBank.name,
      this.selectedPath[0]?.normalizedName,
      this.selectedPath[1]?.normalizedName,
      this.selectedPath[2]?.normalizedName
    )

    const materialData = await this.getMateralData()
    this.savedMaterialTableData = materialData.map((material) => {
      return { data: material, checkedByUser: false, readOnlyChecked: false }
    }) // save material data

    this.nameTextField = this.defaultManagerRuleDialogData.dialogName
    this.saveSelectedSectionType = this.defaultManagerRuleDialogData.selectedSectionFamily
    this.parameterSettings = this.defaultManagerRuleDialogData.parameterSettings
    this.savedSectionTableData = this.defaultManagerRuleDialogData.sectionTableData
  },
  computed: {
    isDialogDisabled(): boolean {
      return this.isDialogReadOnly || this.dialogLoading
    },
    getMainActionBtnText(): string {
      switch (this.defaultManagerRuleDialogData.openOption) {
        case OpenOptions.Write:
          return this.$tc('Létrehoz')
        case OpenOptions.Edit:
          return this.$tc('Mentés')
        case OpenOptions.ReadOnly:
          return this.$tc('Ok')
        default:
          return ''
      }
    },
    isDialogReadOnly(): boolean {
      return this.defaultManagerRuleDialogData.openOption === OpenOptions.ReadOnly
    },
    isSectionFamilySelected(): boolean {
      return this.saveSelectedSectionType.text !== ''
    },
    isMaterialSelected(): boolean {
      return this.selectorBtn === SelectorOptions.Material
    },
    isSectionSelected(): boolean {
      return this.selectorBtn === SelectorOptions.Section
    },
    textFieldRules() {
      return [
        (value) => {
          if (!value.trim()) return this.$t('A név megadása kötelező.')

          if (
            this.defaultManagerRuleDialogData.openOption === OpenOptions.Write &&
            onStockStore.onStockModel?.managerRules.find(
              (managerRule) => value === managerRule.name
            )
          )
            return this.$t('A megadott név már létezik.')

          return true
        },
      ]
    },
    isCreateBtnDisabled(): boolean {
      return this.nameTextField.trim() === '' || !this.isSectionFamilySelected
    },
    getSectionAndMaterialParameters(): {
      sectionParameters: SectionManagerRuleParameter[]
      materialParameters: MaterialManagerRuleParameter[]
    } {
      const sectionParameters: SectionManagerRuleParameter[] = []
      const materialParameters: MaterialManagerRuleParameter[] = []

      this.parameterSettings.forEach((parameter) => {
        if (parameter.name === ParameterNames.MATERIAL) {
          materialParameters.push({
            direction: parameter.direction,
            step: parameter.active ? parameter.step : ParameterSteps.Zero,
          })
        } else if (parameter.active) {
          sectionParameters.push({
            sectionParameterId: parameter.id,
            direction: parameter.direction,
            step: parameter.active ? parameter.step : ParameterSteps.Zero,
          })
        }
      })

      return { sectionParameters: sectionParameters, materialParameters: materialParameters }
    },
    getTableData(): ManagerRuleTableData[] {
      return this.selectorBtn === SelectorOptions.Section
        ? this.savedSectionTableData
        : this.savedMaterialTableData
    },
  },
  methods: {
    findNode(item: ManagerRuleTreeNode, id: string): void {
      if (this.found) return // clicked item already found, skip the rest
      this.selectedPath.push({ id: item.id, normalizedName: item.normalizedName }) // save path
      if (item.id === id) {
        this.found = true // item found
        return
      }
      if (!item.children || !item.children.length || item.children[0].id === '') {
        // item is a fake child (children.id === '' / item.children.length) or leaf node (children: undefined)
        this.selectedPath.pop() // remove lastly saved path
        return
      }
      if (item.children) {
        item.children.forEach((item) => {
          this.findNode(item, id) // expand the search
        })
        if (!this.found) this.selectedPath.pop() // remove lastly saved path, if it is a dead-end
      }
    },
    updateNode(
      item: ManagerRuleTreeNode,
      route: string,
      childrenData: ManagerRuleTreeNode[]
    ): void {
      if (this.found) return
      if (item.normalizedName === this.selectedPath[this.selectedPath.length - 1]?.normalizedName) {
        item.children = childrenData
        this.found = true
      }
      if (item.normalizedName === route && item.children) {
        this.level++
        item.children.forEach((item) => {
          this.updateNode(item, this.selectedPath[this.level]?.normalizedName, childrenData) // expand the search
        })
      }
    },
    async open(id: string): Promise<void> {
      this.found = false
      this.selectedPath = []

      await Promise.all(
        this.items.map(async (item) => {
          this.findNode(item, id)
        })
      )

      const childrenData = await this.getSectionData(
        this.latestSectionBank.name,
        this.selectedPath[0]?.normalizedName,
        this.selectedPath[1]?.normalizedName,
        this.selectedPath[2]?.normalizedName
      )

      this.found = false
      this.level = 0

      await Promise.all(
        this.items.map(async (item) => {
          this.updateNode(item, this.selectedPath[0]?.normalizedName, childrenData)
        })
      )
    },
    async select(...ids: string[]): Promise<void> {
      this.selectedIds.push(...ids)

      this.found = false
      this.selectedPath = []

      await Promise.all(
        this.items.map(async (item) => {
          this.findNode(item, this.selectedIds[0])
        })
      )

      const sectionsData = await this.getSectionData(
        this.latestSectionBank.name,
        this.selectedPath[0]?.normalizedName,
        this.selectedPath[1]?.normalizedName,
        this.selectedPath[2]?.normalizedName
      )

      const parameterData: SectionBankData[] = await getSectionParameters(
        this.latestSectionBank.name,
        this.selectedPath[0]?.normalizedName,
        this.selectedPath[1]?.normalizedName,
        this.selectedPath[2]?.normalizedName
      )

      this.parameterSettings = parameterData.map((parameter) => {
        return {
          name: parameter.normalizedName,
          id: parameter.id,
          active: false,
          direction: this.defaultSelectorDirection,
          step: this.defaultSelectorStep,
        }
      })

      this.parameterSettings = [
        ...this.parameterSettings,
        {
          id: 0,
          name: ParameterNames.MATERIAL,
          active: false,
          direction: this.defaultSelectorDirection,
          step: this.defaultSelectorStep,
        }, // add constant material
      ]

      this.savedSectionTableData = sectionsData.map((section) => {
        return { data: section, checkedByUser: false, readOnlyChecked: false }
      })

      this.savedMaterialTableData = this.savedMaterialTableData.map((material) => {
        return { ...material, checkedByUser: false, readOnlyChecked: false }
      })

      this.saveSelectedSectionType = {
        text: this.selectedPath[this.selectedPath.length - 1]?.normalizedName,
        id: this.selectedPath[this.selectedPath.length - 1]?.id,
      }
    },
    remove(...ids: string[]): void {
      this.selectedIds = this.selectedIds.filter((id) => !ids.includes(id))
    },
    parseSectionBank(
      selectionBank: SectionBankData[],
      level: number,
      addFakeChild = true,
      convertNameToUppercase = false
    ): ManagerRuleTreeNode[] {
      let items: ManagerRuleTreeNode[] = []
      selectionBank.forEach((item) => {
        items.push({
          id: level.toString() + '-' + item.id.toString(),
          name: convertNameToUppercase ? item.name.toUpperCase() : item.name,
          normalizedName: item.normalizedName,
          children: addFakeChild ? [{ id: '', name: '', normalizedName: '' }] : undefined,
        })
      })
      return items
    },
    async getSectionData(
      bankName: string,
      originName?: string | undefined,
      familyName?: string | undefined,
      typeName?: string | undefined
    ): Promise<ManagerRuleTreeNode[]> {
      if (bankName && originName && familyName && typeName) {
        const sectionData = await getSections(bankName, originName, familyName, typeName)
        return this.parseSectionBank(sectionData, 4, false)
      } else if (bankName && originName && familyName) {
        const sectionData = await getSectionTypes(bankName, originName, familyName)
        return this.parseSectionBank(sectionData, 3, false)
      } else if (bankName && originName) {
        const sectionData = await getSectionFamilies(bankName, originName)
        return this.parseSectionBank(sectionData, 2, true, true)
      }
      const sectionData = await getSectionOrigins(bankName)
      return this.parseSectionBank(sectionData, 1)
    },
    async getMateralData(): Promise<ManagerRuleTreeNode[]> {
      const materialData = await getMaterials()
      return this.parseSectionBank(materialData, 1, false)
    },
    async setManagerRulePreview(): Promise<void> {
      this.tableLoading = true
      const { sectionParameters, materialParameters } = this.getSectionAndMaterialParameters

      if (!onStockStore.project || !onStockStore.onStockModel) {
        console.warn('onstock project or model is null')
        return
      }

      if (this.selectedSectionId !== '' && this.changedParameterType === SelectorOptions.Section) {
        const previewData = await createPreviewManagerRuleSection(
          onStockStore.project.id.toString(),
          onStockStore.onStockModel.id.toString(),
          this.selectedSectionId,
          sectionParameters
        )

        if (!previewData) return

        this.savedSectionTableData = this.changeTableData(this.savedSectionTableData, previewData)
      } else if (
        this.selectedMaterialId !== '' &&
        this.changedParameterType === SelectorOptions.Material
      ) {
        const previewData = await createPreviewManagerRuleMaterial(
          onStockStore.project.id.toString(),
          onStockStore.onStockModel.id.toString(),
          this.selectedMaterialId,
          materialParameters[0]
        )

        if (!previewData) return

        this.savedMaterialTableData = this.changeTableData(this.savedMaterialTableData, previewData)
      }
      this.tableLoading = false
    },
    changeTableData(
      tableData: ManagerRuleTableData[],
      previewData: OnStockPreviewManagerRuleBase[]
    ): ManagerRuleTableData[] {
      const newTableData = tableData.map((section) => {
        const isSectionFound = previewData.find((sectionData) => {
          const id = section.data.id.split('-')
          return sectionData.id.toString() === id[1]
        })
        return isSectionFound
          ? { ...section, readOnlyChecked: true }
          : { ...section, checkedByUser: false, readOnlyChecked: false }
      })
      return newTableData
    },
    handleUserCheckboxSelect(
      tableData: ManagerRuleTableData[],
      sectionId: string,
      checkboxValue: boolean
    ): ManagerRuleTableData[] {
      return tableData.map((data) => {
        if (!checkboxValue) return { ...data, checkedByUser: false, readOnlyChecked: false }
        if (data.data.id === sectionId) return { ...data, checkedByUser: true }
        return { ...data, checkedByUser: false }
      })
    },
    setSelectorBtnOnClick(selectorBtn: SelectorOptions): void {
      this.selectorBtn = selectorBtn
    },
    closeBtnClicked(): void {
      this.$emit('input', false)
      this.$emit('closeBtnClicked')
    },
    cancelBtnClicked(): void {
      this.$emit('input', false)
    },
    async mainActionBtnClicked(): Promise<void> {
      this.dialogLoading = true

      const { sectionParameters, materialParameters } = this.getSectionAndMaterialParameters
      const selectedSectionTypeId = this.saveSelectedSectionType.id.split('-')[1]

      if (!onStockStore.project || !onStockStore.onStockModel) {
        console.warn('onstock project or model is null')
        return
      }

      if (this.defaultManagerRuleDialogData.openOption === OpenOptions.Edit) {
        if (!this.defaultManagerRuleDialogData.managerRuleId) return

        const modifiedData = await modifyManagerRule(
          onStockStore.project.id.toString(),
          onStockStore.onStockModel.id.toString(),
          this.defaultManagerRuleDialogData.managerRuleId,
          this.nameTextField,
          parseInt(selectedSectionTypeId),
          sectionParameters,
          materialParameters[0]
        )

        if (!modifiedData) return

        const managerRules = onStockStore.onStockModel.managerRules.map((rule) => {
          if (rule.id === modifiedData.id) return modifiedData
          return rule
        })
        setOnStockModelManagerRules(managerRules)

        this.$emit('editManagerRuleActionBtnClicked', modifiedData)
      } else if (this.defaultManagerRuleDialogData.openOption === OpenOptions.Write) {
        const createdData = await createManagerRule(
          onStockStore.project.id.toString(),
          onStockStore.onStockModel.id.toString(),
          this.nameTextField,
          parseInt(selectedSectionTypeId),
          sectionParameters,
          materialParameters[0]
        )

        if (!createdData) return

        setOnStockModelManagerRules([...onStockStore.onStockModel.managerRules, createdData])

        this.$emit('createManagerRuleActionBtnClicked')
      }

      this.dialogLoading = false
      this.$emit('input', false)
    },
    parameterSelectorChanged(
      rawParameterName: string,
      selectedDirection: number,
      selectedStep: number
    ): void {
      let parameterChanged = false
      this.parameterSettings = this.parameterSettings.map((parameter) => {
        if (parameter.name === rawParameterName) {
          parameterChanged = true
          this.changedParameterType =
            parameter.name === ParameterNames.MATERIAL
              ? SelectorOptions.Material
              : SelectorOptions.Section
          return { ...parameter, direction: selectedDirection, step: selectedStep }
        }
        return parameter
      })

      if (parameterChanged) this.setManagerRulePreview()
    },
    parameterCheckboxChanged(rawParameterName: string, checkboxValue: boolean): void {
      let parameterChanged = false
      this.parameterSettings = this.parameterSettings.map((parameter) => {
        if (parameter.name === rawParameterName) {
          parameterChanged = true
          this.changedParameterType =
            parameter.name === ParameterNames.MATERIAL
              ? SelectorOptions.Material
              : SelectorOptions.Section
          return {
            ...parameter,
            active: checkboxValue,
            direction: this.defaultSelectorDirection,
            step: this.defaultSelectorStep,
          }
        }
        return parameter
      })

      if (parameterChanged) this.setManagerRulePreview()
    },
    async tableCheckboxChanged(checkboxValue: boolean, sectionId: string): Promise<void> {
      const selectedId = sectionId.split('-')[1]
      if (this.selectorBtn === SelectorOptions.Material) {
        this.savedMaterialTableData = this.handleUserCheckboxSelect(
          this.savedMaterialTableData,
          sectionId,
          checkboxValue
        )
        this.selectedMaterialId = this.selectedMaterialId === selectedId ? '' : selectedId // reset material id if the user deactivates the table checkbox
        this.changedParameterType = SelectorOptions.Material
      } else {
        this.savedSectionTableData = this.handleUserCheckboxSelect(
          this.savedSectionTableData,
          sectionId,
          checkboxValue
        )
        this.selectedSectionId = this.selectedSectionId === selectedId ? '' : selectedId // reset section id if the user deactivates the table checkbox
        this.changedParameterType = SelectorOptions.Section
      }

      if (checkboxValue) await this.setManagerRulePreview()
    },
  },
})
