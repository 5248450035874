import { render, staticRenderFns } from "./ManagerRulesPreviewTable.vue?vue&type=template&id=1cb2e529&scoped=true"
import script from "./ManagerRulesPreviewTable.vue?vue&type=script&lang=ts"
export * from "./ManagerRulesPreviewTable.vue?vue&type=script&lang=ts"
import style0 from "./ManagerRulesPreviewTable.vue?vue&type=style&index=0&id=1cb2e529&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cb2e529",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1cb2e529')) {
      api.createRecord('1cb2e529', component.options)
    } else {
      api.reload('1cb2e529', component.options)
    }
    module.hot.accept("./ManagerRulesPreviewTable.vue?vue&type=template&id=1cb2e529&scoped=true", function () {
      api.rerender('1cb2e529', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Dialogs/ManagerRulesDialog/ManagerRulesPreviewTable.vue"
export default component.exports