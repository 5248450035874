var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.primaryItem.value
    ? _c(
        "cs-flex",
        {
          class: ["info-table-item", _vm.color, { open: _vm.open }],
          attrs: { "fill-width": "", vertical: "", "no-content-stretch": "" },
        },
        [
          _c(
            "cs-flex",
            {
              class: [
                "py-3",
                "info-table-row",
                { openable: _vm.primaryItem.children },
                _vm.changed && "changed",
                _vm.deleted && "deleted",
                _vm.added && "added",
              ],
              attrs: { "fill-width": "" },
              on: { click: _vm.onClick },
            },
            [
              _vm.primaryItem.children
                ? _c("chevron-down-svg", { staticClass: "ml-3" })
                : _c("div", { staticClass: "chevron-down-filler ml-3" }),
              _c(
                "cs-flex",
                {
                  staticClass: "info-table-item-name pl-3",
                  attrs: { justify: "flex-start", "fill-width": "" },
                },
                [_c("span", [_vm._v(_vm._s(_vm.primaryItem.name))])]
              ),
              _c(
                "cs-flex",
                {
                  staticClass: "info-table-item-value pl-3",
                  attrs: { justify: "flex-start", "fill-width": "" },
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "", "open-delay": "1000" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "span",
                                  _vm._g(_vm._b({}, "span", attrs, false), on),
                                  [_vm._v(_vm._s(_vm.primaryItem.value))]
                                ),
                                _c(
                                  "cs-latex-field",
                                  { style: { "margin-left": "5px" } },
                                  [_vm._v(_vm._s(_vm.primaryItem.unit))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1653290872
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.primaryItem.value))])]
                  ),
                ],
                1
              ),
              _vm.secondaryItem?.value
                ? _c(
                    "cs-flex",
                    {
                      staticClass: "info-table-item-value pl-3",
                      attrs: { justify: "flex-start", "fill-width": "" },
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "open-delay": "1000" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        _vm._b({}, "span", attrs, false),
                                        on
                                      ),
                                      [_vm._v(_vm._s(_vm.secondaryItem.value))]
                                    ),
                                    _c(
                                      "cs-latex-field",
                                      { style: { "margin-left": "5px" } },
                                      [_vm._v(_vm._s(_vm.secondaryItem.unit))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1761912952
                          ),
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.secondaryItem.value))])]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.primaryItem.children
            ? [
                _c("div", { staticClass: "info-table-separator" }),
                _c(
                  "cs-flex",
                  {
                    staticClass: "info-table-item-child-container",
                    attrs: {
                      "fill-width": "",
                      vertical: "",
                      justify: "flex-start",
                    },
                  },
                  [_vm._t("default")],
                  2
                ),
              ]
            : _vm._e(),
          _c("v-divider", { style: "width: 100%;" }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }