
import ChevronDownSvg from '@/assets/common/ChevronDown.svg.vue'
import { CsFlex, CsLatexField } from '@consteel/csuetify'
import Vue from 'vue'
import { TableNode } from './TableNode'

export default Vue.extend({
  name: 'InfoTableItem',
  components: {
    CsFlex,
    CsLatexField,
    ChevronDownSvg,
  },
  props: {
    changed: {
      type: Boolean,
      default: false,
    },
    deleted: {
      type: Boolean,
      default: false,
    },
    added: {
      type: Boolean,
      default: false,
    },
    closed: {
      type: Boolean,
      default: true,
    },
    primaryItem: {
      type: Object as () => TableNode,
      required: true,
    },
    secondaryItem: {
      type: Object as () => TableNode,
    },
    level: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    color() {
      switch (this.level) {
        case 0:
          return 'color-2'
        case 1:
          return 'color-3'
        default:
          return 'color-4'
      }
    },
  },
  methods: {
    onClick() {
      if (this.primaryItem.children) {
        this.open = !this.open
      }
    },
  },
  created() {
    this.open = !this.closed
  },
})
