
import Vue from 'vue'
import { CsFlex, CsGrid } from '@consteel/csuetify'
import {
  getManagerRuleParameterText,
  ParameterDirections,
  ParameterNames,
  ParameterSteps,
} from '@/modules/onstock'
export default Vue.extend({
  name: 'ManagerRulesParameter',
  components: {
    CsFlex,
    CsGrid,
  },
  props: {
    parameterName: { type: String, default: '' },
    selectorStep: { type: Number, default: ParameterSteps.Default },
    selectorDirection: { type: Number, default: ParameterDirections.UpAndDown },
    parameterSelectorDisabled: { type: Boolean, default: false },
    disableParameter: { type: Boolean, default: false },
  },
  data() {
    return {
      selectorDirectionItems: [
        { text: '+/-', value: ParameterDirections.UpAndDown },
        { text: '-', value: ParameterDirections.Up }, // (-) means a stronger section / material
        { text: '+', value: ParameterDirections.Down }, // (+) means a weeker section / material
      ],
      selectorStepItems: [
        { text: '1', value: ParameterSteps.One },
        { text: '2', value: ParameterSteps.Two },
        { text: '3', value: ParameterSteps.Three },
        { text: '4', value: ParameterSteps.Four },
        { text: '5', value: ParameterSteps.Five },
      ],
      selectedDirectionValue: this.selectorDirection,
      selectedStepValue: this.selectorStep,
    }
  },
  computed: {
    convertRawSectionParameter(): string {
      return getManagerRuleParameterText(this.parameterName as ParameterNames)
    },
  },
  methods: {
    selectorChanged() {
      this.$emit(
        'selectorChanged',
        this.parameterName,
        this.selectedDirectionValue,
        this.selectedStepValue
      )
    },
    checkboxChanged(value: boolean) {
      this.$emit('checkboxChanged', this.parameterName, value)
    },
  },
  watch: {
    selectorStep() {
      this.selectedStepValue = this.selectorStep
    },
    selectorDirection() {
      this.selectedDirectionValue = this.selectorDirection
    },
  },
})
