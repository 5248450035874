var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      staticClass: "box-svg",
      attrs: {
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "Frame" } }, [
        _c("path", {
          attrs: {
            id: "Vector",
            d: "M2 3C2 2.45 2.45 2 3 2H5V0H3C1.34 0 0 1.34 0 3V5H2V3Z",
            fill: _vm.color,
          },
        }),
        _c("path", {
          attrs: {
            id: "Vector_2",
            d: "M2 19V17H0V19C0 20.66 1.34 22 3 22H5V20H3C2.45 20 2 19.55 2 19Z",
            fill: _vm.color,
          },
        }),
        _c("path", {
          attrs: {
            id: "Vector_3",
            d: "M19 0H17V2H19C19.55 2 20 2.45 20 3V5H22V3C22 1.34 20.66 0 19 0Z",
            fill: _vm.color,
          },
        }),
        _c("path", {
          attrs: {
            id: "Vector_4",
            d: "M20 19C20 19.55 19.55 20 19 20H17V22H19C20.66 22 22 20.66 22 19V17H20V19Z",
            fill: _vm.color,
          },
        }),
        _c("path", {
          attrs: {
            id: "Vector_5",
            d: "M18 13.87V8.13C18 7.41 17.62 6.75 17 6.4L12 3.52C11.69 3.34 11.35 3.25 11 3.25C10.65 3.25 10.31 3.34 10 3.52L5 6.39C4.38 6.75 4 7.41 4 8.13V13.87C4 14.59 4.38 15.25 5 15.6L10 18.48C10.31 18.66 10.65 18.75 11 18.75C11.35 18.75 11.69 18.66 12 18.48L17 15.6C17.62 15.25 18 14.59 18 13.87ZM10 16.17L6 13.87V9.24L10 11.57V16.17ZM11 9.84L7.04 7.53L11 5.25L14.96 7.53L11 9.84ZM16 13.87L12 16.17V11.57L16 9.24V13.87Z",
            fill: _vm.color,
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }