
import Vue from 'vue'
import { CsFlex } from '@consteel/csuetify'
import { ManagerRuleTreeNode } from '@/modules/onstock/types/OnStockManagerRuleInterfaces'
export default Vue.extend({
  name: 'ManagerRulesPreviewTable',
  components: {
    CsFlex,
  },
  props: {
    tableTitle: { type: String, default: '' },
    sections: { type: Array as () => Array<ManagerRuleTreeNode>, default: [] },
    selectedFamilyName: { type: String, default: '' },
    isTableReadOnly: { type: Boolean, default: false },
    tableDisabled: { type: Boolean, default: false },
    isMaterialSelected: { type: Boolean, default: false },
  },
  computed: {
    isSectionFamilySelected(): boolean {
      return this.selectedFamilyName !== ''
    },
  },
  methods: {
    checkboxChanged(sectionId: string, value: boolean) {
      this.$emit('checkboxChanged', value, sectionId)
    },
    getSectionOrMaterialName(name: string): string {
      if (this.isMaterialSelected) return name
      return this.selectedFamilyName + ' ' + name
    },
  },
})
