
import ChevronDownSvg from '@/assets/common/ChevronDown.svg.vue'
import { modelViewerStore } from '@/store/modelViewer.store'
import { CsFlex } from '@consteel/csuetify'
import Vue, { CreateElement, VNode } from 'vue'
import InfoTableItem from './InfoTableItem.vue'
import { TableNode } from './TableNode'

export default Vue.extend({
  name: 'InfoTable',
  components: {
    CsFlex,
    ChevronDownSvg,
    InfoTableItem,
  },
  props: {
    items: {
      type: Array as () => TableNode[],
      default: () => [] as TableNode[],
    },
    compareItems: {
      type: Array as () => TableNode[],
      default: () => [] as TableNode[],
    },
    closed: {
      type: Boolean,
      default: false,
    },
    compare: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      required: false,
    },
  },
  data: () => {
    return {
      open: true,
    }
  },
  computed: {
    itemsToDisplay(): TableNode[] {
      if (!this.compare) return this.items

      if (this.items.length) {
        return this.items.map((item, i) => {
          return {
            ...item,
            valueToCompare: this.compareItems[i]?.value,
            children: item.children?.map((child, j) => {
              return {
                ...child,
                valueToCompare: this.compareItems[i]?.children?.[j]?.value,
                children: child.children?.map((gChild, k) => {
                  return {
                    ...gChild,
                    valueToCompare: this.compareItems[i]?.children?.[j]?.children?.[k]?.value,
                  }
                }),
              }
            }),
          }
        })
      }

      return this.compareItems.map((item) => {
        return {
          name: item.name,
          value: undefined,
          valueToCompare: item.value,
          children: item.children?.map((child, j) => {
            return {
              ...child,
              value: undefined,
              valueToCompare: item.children?.[j]?.value,
            }
          }),
        }
      })
    },
  },
  created() {
    this.open = !this.closed
  },
  render(h) {
    function renderItems(
      h: CreateElement,
      items: TableNode[],
      level: number,
      compare: boolean
    ): VNode[] {
      const nextLevel = level + 1
      return items.map((item): VNode => {
        const children = item.children ? renderItems(h, item.children, nextLevel, compare) : []

        const renderProps = compare
          ? {
              changed: item.value && item.valueToCompare && item.value !== item.valueToCompare,
              deleted: !!item.value && !item.valueToCompare,
              added: !item.value && !!item.valueToCompare,
              primaryItem: {
                ...item,
                value: item.value || '-',
                unit: item.value ? item.unit : undefined,
              },
              secondaryItem: {
                ...item,
                value: item.valueToCompare || '-',
                unit: item.valueToCompare ? item.unit : undefined,
                children: item.childrenToCompare,
              },
              level,
            }
          : {
              primaryItem: item,
              secondaryItem: undefined,
              level,
            }

        return h(
          'info-table-item',
          {
            props: renderProps,
          },
          [children.length ? children : []]
        )
      })
    }

    return h(
      'cs-flex',
      {
        class: ['info-table', { open: this.open }],
        props: {
          fillWidth: true,
          noContentStretch: true,
          vertical: true,
        },
      },
      this.header
        ? [
            h(
              'cs-flex',
              {
                class: ['info-table-header', 'pl-3', 'py-3'],
                props: {
                  fillWidth: true,
                  justify: 'flex-start',
                },
                on: {
                  click: () => {
                    this.open = !this.open
                  },
                },
              },
              [h('chevron-down-svg', { class: ['mr-3'] }, []), h('span', this.header)]
            ),
            h('div', { class: 'info-table-separator' }),
            h('info-table-item', {
              class: ['info-table-content-header'],
              props: {
                primaryItem: { name: 'Versions', value: modelViewerStore.model.modelNumber },
                secondaryItem: {
                  value: this.compare ? modelViewerStore.secondaryModel.modelNumber : undefined,
                },
                level: 1,
              },
            }),
            h(
              'cs-flex',
              { class: 'info-table-content', props: { fillWidth: true, vertical: true } },
              renderItems(h, this.itemsToDisplay, 0, this.compare)
            ),
          ]
        : [
            h('info-table-item', {
              props: {
                primaryItem: { name: 'Versions', value: modelViewerStore.model.modelNumber },
                secondaryItem: {
                  value: this.compare ? modelViewerStore.secondaryModel.modelNumber : undefined,
                },
                level: 1,
              },
            }),
            renderItems(h, this.itemsToDisplay, 0, this.compare),
          ]
    )
  },
})
